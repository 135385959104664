<template>
  <div class="vehicle-form__price">
    <RdxDivider class="mb-4 mt-5" />
    <div class="new-vehicle-view__grid">
      <div>
        <CiHeading level="2" class="mb-2">
          {{ $t("stock.shared-form.price.header") }}
        </CiHeading>
        <RdxInfo>
          {{ $t("stock.shared-form.price.description") }}
        </RdxInfo>
      </div>
      <ValidationObserver>
        <div class="col-xl-8 p-4">
          <div class="row">
            <div class="col-lg-6">
              <VRdxInput
                ref="msrp_price"
                v-model="vehicle.msrp_price"
                v-currency="currencyFormat"
                class="vehicle-form__price--price-input"
                :pending="pending.load || pending.save"
                :label="$t('stock.shared-form.price.retail-price-input-label')+'**'"
                :name="$t('stock.shared-form.price.retail-price-input-label')"
                :rules="msrpPriceValidationRules"
                data-cy="msrp_price"
                vid="msrp_price"
              />
            </div>
            <div class="col-lg-6">
              <RdxInput
                ref="monthly_price"
                v-model="vehicle.monthly_price"
                v-currency="currencyFormat"
                class="vehicle-form__price--price-input"
                :pending="pending.load || pending.save"
                :label="$t('stock.shared-form.price.monthly-price-input-label')"
                data-cy="monthly_price"
              />
            </div>
          </div>

          <RdxCheckbox
            ref="on_sale"
            v-model="vehicle.on_sale"
            :pending="pending.load || pending.save"
            :label="$t('stock.shared-form.price.on-sale-input-label')"
            data-cy="on_sale"
          />

          <div class="row">
            <div class="col-lg-6">
              <VRdxInput
                ref="sale_price"
                v-model="vehicle.sale_price"
                v-currency="currencyFormat"
                :pending="pending.load || pending.save"
                class="vehicle-form__price--price-input"
                :label="$t('stock.shared-form.price.sale-price-input-label')"
                :name="$t('stock.shared-form.price.sale-price-input-label')"
                data-cy="sale_price"
                :rules="{
                  lower_than: {
                    target: '@msrp_price',
                    currencyFormat: currencyFormat
                  }
                }"
              />
            </div>
          </div>
          <div class="row">
            <div class="col">
              <RdxCheckbox
                v-model="vehicle.price_hidden"
                class="mt-4"
                :pending="pending.load || pending.save"
                :label="$t('stock.shared-form.price.hide-price')"
                data-cy="price_hidden"
              />
              <RdxInfo>
                {{ $t("stock.shared-form.price.hide-price-description") }}
              </RdxInfo>
            </div>
          </div>
        </div>
      </ValidationObserver>
    </div>
  </div>
</template>

<script>
import {
  RdxInfo,
  RdxInput,
  RdxCheckbox,
  RdxDivider
} from "@raffine/rdx-ui/lib/rdx-ui.umd";
import CiHeading from "@/components/atoms/CiHeading";

import { ValidationObserver, withValidation } from "vee-validate";
const VRdxInput = withValidation(RdxInput, ({ errors }) => ({ errors }));

export default {
  name: "VehicleFormPrice",

  components: {
    RdxInfo,
    RdxInput,
    RdxCheckbox,
    RdxDivider,
    CiHeading,

    VRdxInput,
    ValidationObserver
  },

  props: {
    pending: {
      required: true,
      type: Object,
      default: () => {
        return {
          load: undefined,
          save: undefined
        };
      }
    },

    initialMsrpPrice: {
      type: [Number, String],
      default: null
    },
    initialMonthlyPrice: {
      type: [Number, String],
      default: null
    },
    initialSalePrice: {
      type: [Number, String],
      default: null
    },
    initialCurrency: {
      type: String,
      default: null
    },
    initialOnSale: {
      type: Boolean,
      default: null
    },
    initialPriceHidden: {
      type: Boolean,
      default: false
    },
    ownerLocale: {
      type: String,
      default: null
    }
  },

  data() {
    return {
      vehicle: {
        msrp_price: null,
        monthly_price: null,
        sale_price: null,
        price_hidden: null,
        on_sale:null
      }
    };
  },

  computed: {
    currencyFormat() {
      return {
        distractionFree: true,
        locale: this.ownerLocale || undefined,
        currency: this.initialCurrency
      };
    },

    msrpPriceValidationRules() {
      return this.vehicle.price_hidden
        ? "required"
        : {
            required: true,
            is_positive: { currencyFormat: this.currencyFormat }
          };
    }
  },

  watch: {
    initialMsrpPrice(newValue, oldValue) {
      if (newValue && oldValue === null && this.pending.load)
        this.$ci.setValue(this.$refs.msrp_price, newValue);
    },

    initialMonthlyPrice(newValue, oldValue) {
      if (newValue && oldValue === null && this.pending.load)
        this.$ci.setValue(this.$refs.monthly_price, newValue);
    },
    initialSalePrice(newValue, oldValue) {
      if (newValue) {
        if (oldValue === null && this.pending.load) {
          this.$nextTick(() => {
            this.$ci.setValue(this.$refs.sale_price, newValue);
          });
        }
      }
    },

    initialCurrency(value) {
      this.vehicle.currency = value;
    },
    initialOnSale(value) {
      this.vehicle.on_sale = value;
    },

    initialPriceHidden(value) {
      this.vehicle.price_hidden = value;
    },

    "vehicle.msrp_price"(price) {
      this.$emit("vehicle:msrp_price", this.priceToEmit(price));
    },

    "vehicle.monthly_price"(price) {
      this.$emit("vehicle:monthly_price", this.priceToEmit(price));
    },

    "vehicle.on_sale"(on_sale) {
      this.$emit("vehicle:on_sale", on_sale);
    },

    "vehicle.sale_price"(price) {
      this.$emit("vehicle:sale_price", this.priceToEmit(price));
    },
    "vehicle.price_hidden"(value) {
      if (!this.vehicle.msrp_price) this.$ci.setValue(this.$refs.msrp_price, 0);
      this.forceValidation("msrp_price");
      this.$emit("vehicle:price_hidden", value);
    },

  },

  methods: {
    priceToEmit(price) {
      return Number(price) ? price : this.$ci.parse(price, this.currencyFormat);
    },
    forceValidation(ref) {
      this.$nextTick(() => {
        if (this.$refs[ref]) this.$refs[ref].validate();
      });
    }
  }
};
</script>

<style lang="scss">
.vehicle-form__price {
  &--price-input {
    .rdx-input__input-wrapper {
      input[type="number"] {
        appearance: textfield;
        &::-webkit-inner-spin-button,
        &::-webkit-outer-spin-button {
          -webkit-appearance: none;
          margin: 0;
        }
      }
    }
  }
}
</style>
