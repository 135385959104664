<!-- eslint-disable vue/no-v-html -->
<template>
  <div class="vehicle-form__media">
    <RdxDivider class="mb-4 mt-5" />
    <div class="new-vehicle-view__grid">
      <div>
        <CiHeading level="2" class="mb-2">
          {{ $t("stock.shared-form.media.header") }}
        </CiHeading>
        <RdxInfo>
          {{ $t("stock.shared-form.media.description") }}
        </RdxInfo>
      </div>
      <div class="col-xl-8 p-4">
        <RdxCheckbox
          v-model="vehicle.has360gallery"
          class="mt-4"
          :pending="pending.load || pending.save"
          :label="$t('stock.shared-form.media.has360gallery')"
          data-cy="has360gallery"
        />
        <!-- TODO: dnd upload -->
        <!-- <rdx-icon class="ri-upload-cloud-2-line rdx-icon--24 rdx-icon--blue" /> -->


        <RdxInfo v-if="!base64Images.length">
          {{ $t("stock.shared-form.media.note-1") }}
        </RdxInfo>

        <div class="upload-media">
          <CiHeading level="2">Exterior</CiHeading>
          <div style="width:100%">
          <RdxFilePicker
            :disabled="pending.load || pending.save"
            :accept="`image/*`"
            label="Upload Exterior Images (widthxheight: 1920px x 1080px)"
            :custom-file-label="$t('stock.shared-form.media.select-file-button')"
            multiple
            data-cy="images"
            @change="uploadPhotos($event, 'ext')"
          />
          </div>
          <template v-for="(image, i) in base64ImagesExterior">
            <div
              :key="`media-image-${i}`"
              class="upload-media__image-wrapper"
              :class="imageWrapperClasses(i)"
            >
              <img class="upload-media__image" :src="image.file" />
            </div>
          </template>
        </div>

        <div class="upload-media">
          <CiHeading level="2">Interior</CiHeading>
          <div style="width:100%">
            <RdxFilePicker
              :disabled="pending.load || pending.save"
              :accept="`image/*`"
              label="Upload Interior Images (widthxheight: 1920px x 1080px)"
              :custom-file-label="$t('stock.shared-form.media.select-file-button')"
              multiple
              data-cy="images"
              @change="uploadPhotos($event, 'int')"
            />
          </div>
          <div
            v-if="imageWrapperEmptyVisibleInt"
            :class="imageWrapperEmptyClasses"
          />
          <template v-for="(image, i) in base64ImagesInterior">
            <div
              :key="`media-image-${i}`"
              class="upload-media__image-wrapper"
              :class="imageWrapperClasses(i)"
              draggable="true"
            >
              <img class="upload-media__image" :src="image.file" />
            </div>
          </template>
        </div>

        <label>Order below</label>
        <div class="upload-media" @drop="drop" @dragover="dragover">
          <div
            v-if="imageWrapperEmptyVisible"
            :class="imageWrapperEmptyClasses"
            @dragenter="dragoverElement($event, -1)"
          />
          <template v-for="(image, i) in base64Images">
            <div
              :key="`media-image-${i}`"
              class="upload-media__image-wrapper"
              :class="imageWrapperClasses(i)"
              draggable="true"
              @dragstart="dragstart($event, i)"
              @dragenter="dragoverElement($event, i)"
              @dragend="dragend"
            >
              <img class="upload-media__image" :src="image.file" />
              <RdxButton
                class="upload-media__remove"
                rect
                @click="removePhoto(i)"
              >
                <RdxIcon class="ri-delete-bin-6-line rdx-icon--16" />
              </RdxButton>
            </div>
          </template>
        </div>

        <RdxInfo icon="ri-information-line">
          {{ $t("stock.shared-form.media.note-4") }}
        </RdxInfo>
        <RdxInfo icon="ri-information-line">
          <span v-html="$t('stock.shared-form.media.note-3')" />
        </RdxInfo>

        <RdxInput
          v-model="vehicle.video_url"
          :disabled="pending.load || pending.save"
          :label="$t('stock.shared-form.media.video-input-label')"
          :placeholder="$t('stock.shared-form.media.video-input-placeholder')"
          data-cy="video"
        >
          <template #prefix>
            <RdxIcon class="ri-youtube-fill" />
          </template>
        </RdxInput>
      </div>
    </div>
  </div>
</template>

<script>
import { serialise } from "kitsu-core";

Array.prototype.removeItem = function(item) {
  return this.splice(this.indexOf(item), 1);
};

function getBase64Image(file) {
  return new Promise((resolve, reject) => {
    if (!file) return reject(null);

    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function(e) {
      return resolve(e.target.result);
    };
  });
}

import ASUpload from "@/utils/active-storage-upload";
import {
  RdxIcon,
  RdxInfo,
  RdxInput,
  RdxButton,
  RdxFilePicker,
  RdxCheckbox,
  RdxDivider
} from "@raffine/rdx-ui/lib/rdx-ui.umd";
import CiHeading from "@/components/atoms/CiHeading";

export default {
  name: "VehicleFormMedia",
  components: {
    RdxIcon,
    RdxInfo,
    RdxCheckbox,
    RdxInput,
    RdxButton,
    RdxFilePicker,
    RdxDivider,
    CiHeading
  },

  props: {
    pending: {
      type: Object,
      default: () => {
        return {
          load: undefined,
          save: undefined
        };
      }
    },

    initialPhotos: {
      type: Array,
      default: () => []
    },
    initialVideo: {
      type: String,
      default: ""
    },
    initialHas360gallery: {
      type: Boolean,
      default: false
    }
  },

  data() {
    return {
      uploadedIds: [],
      base64Images: [],
      // progress: [],
      pendingMedia: false,
      vehicle: {
        video_url: null,
        has360gallery: null
      },
      currentPhotoIndex: null,
      currentlyMovingPhotoIndex: null
    };
  },

  computed: {
    base64ImagesExterior() {
      return this.base64Images.filter(item => {
        return item.photo_type === "ext";
      });
    },

    base64ImagesInterior() {
      return this.base64Images.filter(item => {
        return item.photo_type === "int";
      });
    },

    imageWrapperEmptyVisibleInt() {
      return (
        this.currentlyMovingPhotoIndex !== 0 &&
        (this.currentPhotoIndex === 0 || this.currentPhotoIndex === -1)
      );
    },

    imageWrapperEmptyVisible() {
      return (
        this.currentlyMovingPhotoIndex !== 0 &&
        (this.currentPhotoIndex === 0 || this.currentPhotoIndex === -1)
      );
    },

    imageWrapperEmptyClasses() {
      return {
        "upload-media__image-wrapper": true,
        "upload-media__image-wrapper--empty": true,
        "upload-media__image-wrapper--empty-active":
          this.currentPhotoIndex === -1
      };
    }
  },

  watch: {
    initialPhotos(photos) {
      [...photos]
        .sort((a, b) => a.attributes.sequence - b.attributes.sequence)
        .forEach((photo, i) => {
          this.getBase64ImageFromURL(photo.attributes.small_path).then(
            base64 => {
              this.$set(this.base64Images, i, {
                file: base64,
                photo_type: photo.attributes.photo_type
              });
            }
          );
        });
      this.uploadedIds = [...photos]
        .sort((a, b) => a.attributes.sequence - b.attributes.sequence)
        .map(photo => {
          return { id: photo.id, photo_type: photo.attributes.photo_type };
        });
    },

    initialHas360gallery(value) {
      console.log("has360gallery");
      this.vehicle.has360gallery = value;
    },

    initialVideo(value) {
      this.vehicle.video_url = value;
    },
    "vehicle.has360gallery"(value) {
      this.$emit("vehicle:has360gallery", value);
    },
    "vehicle.video_url"(video) {
      this.$emit("vehicle:video_url", video);
    }
  },

  methods: {
    uploadPhotos(files,photo_type) {
      if (files) {
        let instance = this.$toast.open({
          message: `<span class="v-toast__loader"></span><span>${this.$t(
            "stock.shared-form.media.loading"
          )}</span>`,
          type: "info",
          duration: 0
        });
        this.pendingMedia = true;
        this.$emit("uploading", true);
        const URL = process.env["VUE_APP_API_URL"];

        const uploadedMediaPromises = [...files].map((file, i) => {
          return ASUpload(file, URL, this)
            .then(({ signed_id }) => {
              const PAYLOAD = serialise("photo", {
                file: signed_id,
                sequence: i,
                photo_type: photo_type
              });

              return this.$http.post("photos", PAYLOAD).then(({ data }) => {
                this.uploadedIds.push({
                  id: data.data.id,
                  photo_type: photo_type
                });
                this.emitUploadedIds();
              });
            })
            .then(() => {
              getBase64Image(file).then(file => {
                this.$set(this.base64Images, this.base64Images.length, {
                  file: file,
                  photo_type: photo_type
                });
              });
            });
        });

        Promise.all(uploadedMediaPromises)
          .then(() => {
            this.$toast.success(
              this.$t("stock.shared-form.media.success-added")
            );
          })
          .finally(() => {
            instance.dismiss();
            this.pendingMedia = false;
            this.$emit("uploading", false);
          });
      } else {
        this.uploadedIds = [];
        this.base64Images = [];
      }
    },

    getBase64ImageFromURL(url) {
      return this.$http.get(url, { responseType: "blob" }).then(file => {
        return getBase64Image(file.data).then(file => {
          return file;
        });
      });
    },

    // directUploadWillStoreFileWithXHR(xhr) {
    //   console.warn("directUploadWillStoreWithXHR", xhr);
    //   xhr.upload.addEventListener("progress", event => this.onProgress(event));
    // },
    // onProgress(event) {
    //   console.warn("onProgress", event);
    //   const { loaded, total } = event;
    //   this.progress = parseInt((loaded / total) * 100);
    // },

    removePhoto(index) {
      this.base64Images.splice(index, 1);

      this.uploadedIds.splice(index, 1);
      this.emitUploadedIds();

      // this.$http.delete(`photos/${id}`).then(() => {
      //   this.$toast.success("Successfully deleted this media file!");
      // });
    },

    dragstart(ev, index) {
      ev.dataTransfer.setData("index", index);
      ev.dataTransfer.dropEffect = "move";
      this.currentlyMovingPhotoIndex = index;
    },

    dragend() {
      this.currentPhotoIndex = null;
      this.currentlyMovingPhotoIndex = null;
    },
    dragover() {
      event.preventDefault();
    },
    dragoverElement(ev, index) {
      event.preventDefault();
      this.currentPhotoIndex = index;
    },
    drop(ev) {
      event.preventDefault();
      let oldIndex = ev.dataTransfer.getData("index");
      let newIndex = this.currentPhotoIndex;
      if (this.currentlyMovingPhotoIndex > this.currentPhotoIndex) newIndex++;
      this.reorderPhotos(oldIndex, newIndex);
    },

    reorderPhotos(oldIndex, newIndex) {
      this.base64Images.splice(
        newIndex,
        0,
        this.base64Images.splice(oldIndex, 1)[0]
      );

      this.uploadedIds.splice(
        newIndex,
        0,
        this.uploadedIds.splice(oldIndex, 1)[0]
      );

      this.emitUploadedIds();
    },

    imageWrapperClasses(i) {
      return {
        "upload-media__image-wrapper--empty-place-after":
          i === this.currentPhotoIndex &&
          this.currentPhotoIndex + 1 !== this.currentlyMovingPhotoIndex &&
          i !== this.currentlyMovingPhotoIndex,
        "upload-media__image-wrapper--grayed-out":
          i === this.currentlyMovingPhotoIndex
      };
    },

    emitUploadedIds() {
      let ids = this.uploadedIds.length ? this.uploadedIds : [null];
      this.$emit("vehicle:photos", ids);
    }
  }
};
</script>

<style lang="scss">
.upload-media {
  display: flex;
  flex-wrap: wrap;

  &__image-wrapper {
    width: fit-content;
    display: inline-flex;
    height: 80px;
    margin-left: 0.25rem;
    margin-bottom: 0.25rem;
    position: relative;

    &:hover {
      cursor: pointer;
      outline-offset: -3px;
      outline: 3px solid $blue-5;
    }
    &--empty-place-after {
      margin-right: 41px;
      position: relative;
      &:after {
        content: "";
        width: 35px;
        height: 100%;
        position: absolute;
        left: 100%;
        margin: 0 4px;
        border: 3px solid $blue-5;
      }
    }

    &--grayed-out {
      filter: grayscale(100%);
      opacity: 0.3;
    }

    &--empty {
      border: 3px solid $grey-4;
      transition: all 50ms ease-in-out;
      width: 35px;
      &-active {
        border: 3px solid $blue-5;
      }
    }
  }

  &__image {
    display: block;
    height: 100%;
  }

  &__remove {
    display: none;
    position: absolute;
    z-index: 1;
    top: 0;
    right: 0;
    transform: translate(0.7rem, -0.7rem);
    background: $grey-1;
    color: $red-7;

    &:hover {
      background: $red-7;
      color: $grey-1;
    }
  }

  &__image-wrapper:hover &__remove {
    display: block;
  }
}

// temporary
.vehicle-form__media {
  .rdx-file-picker__file {
    display: none;
  }
}
</style>
