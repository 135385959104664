import { deattribute } from "kitsu-core";
import debounce from "@/utils/debounce";

import vehicleDetails from "./vehicle-form/details.vue";
import vehicleAvailability from "./vehicle-form/availability.vue";
import vehicleMedia from "./vehicle-form/media.vue";
import vehicleSpecification from "./vehicle-form/specification.vue";
import vehiclePrice from "./vehicle-form/price.vue";
import vehicleEquipment from "./vehicle-form/equipment.vue";
import vehicleAttachment from "./vehicle-form/attachment.vue";
import vehiclePublication from "./vehicle-form/publication.vue";
import vehicleNavigation from "./vehicle-form/navigation.vue";

export default {
  name: "VehicleFormMixin",

  components: {
    vehicleDetails,
    vehicleAvailability,
    vehicleMedia,
    vehicleSpecification,
    vehiclePrice,
    vehicleEquipment,
    vehicleAttachment,
    vehiclePublication,
    vehicleNavigation
  },

  data() {
    return {
      pending: {
        load: false,
        save: false
      },
      mediaIsUploading: false,
      options: {
        brands: [],
        nameplates: [],
        companies: [],
        photos: [],
        listings: [],
        engines: [
          { value: "petrol", label: "Petrol" },
          { value: "diesel", label: "Diesel" },
          { value: "bev", label: "Electric (“BEV”)" },
          { value: "mhev", label: "Mild hybrid (“MHEV”)" },
          { value: "phev", label: "Plug-in hybrid (“PHEV”)" }
        ],
        colors: [],
        colorGroups: [
          { value: "white", label: "White" },
          { value: "black", label: "Black" },
          { value: "silver", label: "Silver" },
          { value: "gray", label: "Grey" },
          { value: "red", label: "Red" },
          { value: "green", label: "Green" },
          { value: "bronze", label: "Bronze" },
          { value: "blue", label: "Blue" },
          { value: "yellow", label: "Yellow" },
          { value: "orange", label: "Orange" }
        ],
        transmission: ["manual", "auto"],
        saleState: ["Free", "Sold"],
        featureCategories: [
          "exterior",
          "interior",
          "lights",
          "safety",
          "wheels",
          "seats",
          "performance",
          "infotainment",
          "convenience"
        ]
      },
      vehicleTitle: "...",
      vehicle: {
        brand: null,
        nameplate: null,
        version: null,
        drivetrain: null,
        short_description: null,
        status: "stock",
        delivery_date: null,
        owner: null,
        video_url: null,
        state: "new",
        production_year: null,
        model_year: null,
        vin: null,
        mileage: 0,
        transmission: null,
        sale_state: null,
        on_sale: null,
        model_no: null,
        external_id: null,
        color_code_ext: null,
        color_name_ext: null,
        color_code_int: null,
        color_name_int: null,
        equipment_no: null,
        designer_choice: null,
        engine_type: [],
        power: null,
        branch_code: "",
        branch_approved: null,
        engine_capacity: null,
        battery_capacity: null,
        color: null,
        msrp_price: null,
        monthly_price: null,
        sale_price: null,
        price_hidden: false,
        has360gallery: null,
        long_description: null,
        approved_specs: null,
        features_standard: [],
        features_optional: [],
        power_unit: null,
        currency: null,
        owner_locale: null,
        mileage_unit: null,
        attachment_url: null,
        listings: null,
        interior_color: null
      },
      relationships: {
        brand: undefined,
        nameplate: undefined,
        owner: undefined,
        color: {
          id: 16

        },
        photos: undefined,
        listings: undefined
      },
      anchors: [
        { name: "vehicle-form__details", offset: Number },
        { name: "vehicle-form__availability", offset: Number },
        { name: "vehicle-form__media", offset: Number },
        { name: "vehicle-form__specification", offset: Number },
        { name: "vehicle-form__price", offset: Number },
        { name: "vehicle-form__equipment", offset: Number },
        { name: "vehicle-form__attachment", offset: Number },
        { name: "vehicle-form__publication", offset: Number }
      ],
      activeAnchor: "vehicle-form__details"
    };
  },

  watch: {
    "options.brands"(items) {
      if (items.length && this.relationships.brand) {
        this.vehicle.brand = this.$__getRelated(
          items,
          this.relationships.brand.id
        );
      }
    },
    "options.nameplates"(items) {
      if (items.length && this.relationships.nameplate) {
        this.vehicle.nameplate = this.$__getRelated(
          items,
          this.relationships.nameplate.id
        );
      }
    },
    "options.companies"(items) {
      if (items.length && this.relationships.owner) {
        this.vehicle.owner = this.$__getRelated(
          items,
          this.relationships.owner.id
        );
      }
    },
    "options.colors"(items) {
      if (items.length && this.relationships.color) {
        this.vehicle.color = this.$__getRelated(
          items,
          this.relationships.color.id
        );
      }
    }
  },

  computed: {
    ID() {
      return this.$route.params.id || undefined;
    }
  },

  mounted() {
    this.calculateAnchors();
    if (this.$refs["scrollable"]) {
      this.$refs["scrollable"].addEventListener(
        "scroll",
        debounce(this.handleScroll, 50)
      );
    }
  },

  beforeDestroy() {
    if (this.$refs["scrollable"]) {
      this.$refs["scrollable"].removeEventListener("scroll", this.handleScroll);
    }
  },

  methods: {
    $__setVehicle(key, data) {
      this.vehicle[key] = data;

      // TODO: refactor
      const updateRelation = relation => {
        if (data?.id)
          this.relationships[relation] = {
            id: String(data.id),
            type: relation
          };
        else delete this.relationships[relation];
      };

      if (key === "brand") updateRelation("brand");
      if (key === "nameplate") updateRelation("nameplate");
      if (key === "color") updateRelation("color");
      if (key === "owner") updateRelation("owner");
      if (key === "photos") {
        this.relationships.photos = data.map(item => {
          return {
            id: item.id === null ? null : String(item.id),
            type: "photos",
            photo_type: item.photo_type
          };
        });
      }
      if (key === "listings") {
        this.relationships.listings = data.map(id => {
          return { id: String(id), type: "listings" };
        });
        if (!this.relationships.listings.length)
          this.relationships.listings = [{ id: null }];
      }
    },

    $__fetchOptions() {
      this.$http.get("brands").then(response => {
        this.options.brands = Object.values(
          deattribute(response.data.data)
        ).map(brand => {
          return { label: brand.name, value: brand.name, id: brand.id };
        });
      });

      this.$http.get("nameplates").then(response => {
        this.options.nameplates = Object.values(
          deattribute(response.data.data)
        ).map(nameplate => {
          return {
            label: nameplate.name,
            value: nameplate.name,
            id: nameplate.id,
            brandId: nameplate.relationships?.brand.data.id
          };
        });
      });

      this.$http.get("colors").then(response => {
        this.options.colors = Object.values(
          deattribute(response.data.data)
        ).map(color => {
          return {
            label: color.name,
            value: color.name,
            canonical: color.canonical,
            id: color.id,
            ownerId:
              color.relationships?.owner.data.type === "company"
                ? color.relationships?.owner.data.id
                : null,
            brandId:
              color.relationships?.owner.data.type === "brand"
                ? color.relationships?.owner.data.id
                : null
          };
        });
      });

      this.$http.get("companies").then(response => {
        this.options.companies = Object.values(
          deattribute(response.data.data)
        ).map(company => {
          return {
            label: company.name,
            value: company.name,
            id: company.id
          };
        });
      });

      this.$http.get("listings").then(response => {
        this.options.listings = Object.values(
          deattribute(response.data.data)
        ).map(listing => {
          return {
            name: listing.title,
            created: null,
            id: listing.id,
            owner: listing.owner_name
          };
        });
      });
    },

    $__getRelated(array, value) {
      return array.find(({ id }) => {
        return parseInt(id) === parseInt(value);
      });
    },

    calculateAnchors() {
      this.anchors = this.anchors.map(item => {
        return this.$refs[item.name]
          ? {
              name: item.name,
              offset: this.$refs[item.name].$el.offsetTop
            }
          : item;
      });
    },

    handleScroll() {
      this.calculateAnchors();
      let scrollPosition = this.$refs["scrollable"].scrollTop;
      let activeAnchorObject = this.anchors
        .filter(anchor => anchor.offset <= scrollPosition + 30)
        .slice(-1)[0];
      if (!activeAnchorObject) activeAnchorObject = this.anchors[0];
      this.activeAnchor = activeAnchorObject.name;
    }
  }
};
